import { useState } from 'react';
import { Outlet } from 'react-router-dom';
// @mui
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
// components
import Topbar from './Topbar';
import Sidebar from './Sidebar';
// constants
import { HEADER, NAVBAR } from '../../theme/cssStyles';
// hooks
import useCollapseDrawer from '../../hooks/useCollapseDrawer';

const MainStyle = styled(
  'main',
  {
    shouldForwardProp: (prop) => prop !== 'collapseClick',
  }
)(({ theme, collapseClick }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: HEADER.APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  ...(collapseClick && {
    marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
  }),
  [theme.breakpoints.up('lg')]: {
    paddingTop: HEADER.APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    ...(collapseClick && {
      marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
  }
}));

const Layout = () => {
  const [open, setOpen] = useState(false);

  const { collapseClick } = useCollapseDrawer();

  return (
    <Box
      sx={{
        display: { lg: 'flex' },
        minHeight: { lg: 1 },
      }}
    >
      <Topbar onOpenSidebar={() => setOpen(true)} />

      <Sidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />

      <MainStyle collapseClick={collapseClick}>
        <Outlet />
      </MainStyle>
    </Box>
  );
};

export default Layout;
