import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, isCollapsed = false, sx }) {
  const logo = <Box component="img" src={isCollapsed ? "/static/logo.png" : "/static/logo.svg"} sx={isCollapsed ? { width: 50, height: 50, ...sx } : { width: 150, height: 70, ...sx }} />

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
