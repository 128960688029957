import {
  useRef,
  useEffect,
  useReducer,
  useCallback,
  createContext,
} from "react";
// firebase
import {
  doc,
  onSnapshot,
  deleteField,
  updateDoc,
  setDoc,
} from "firebase/firestore";
// service firebase
import { db } from "../services/firebase";
// actions
import actionTypes from "../common/actionTypes";
// reducer
import { storeReducer } from "../reducers";
// context
import useAuthContext from "../hooks/useAuthContext";
// constants
import { SETTING_TYPES } from "../common/constants";

// Stores constants
const SHOPS = "Shops";
const SETTINGS = "Settings";

const processNestedFields = (fields) =>
  Object.entries(fields).reduce((acc, [key, value]) => {
    if (typeof value === "object") {
      return {
        ...acc,
        [key]: {
          ...processNestedFields(value),
        },
      };
    }

    return {
      ...acc,
      ...(value ? { [key]: value } : { [key]: deleteField() }),
    };
  }, {});

const initialState = {
  loading: false,
  error: null,
  store: null,
  storeNotFound: false,
};

const StoresContext = createContext({
  ...initialState,
  search: () => {},
  update: () => {},
  removeStore: () => {},
});

const StoresProvider = ({ children }) => {
  const [state, dispatch] = useReducer(storeReducer, initialState);

  const { user } = useAuthContext();

  const unsubscribeCallbacks = useRef([]);

  useEffect(
    () => () => unsubscribeCallbacks.current.forEach((callback) => callback()),
    []
  );

  const fetchStoreTemplates = ({ settings: { templates }, id }) => {
    try {
      for (const templateId of Object.values(templates)) {
        dispatch({ type: actionTypes.FETCH_STORE_TEMPLATE });

        const unsubscribe = onSnapshot(
          doc(db, SHOPS, id, SETTINGS, templateId),
          (docSnapshot) => {
            const template = docSnapshot.data();

            if (template) {
              dispatch({
                type: actionTypes.FETCH_STORE_TEMPLATE_SUCCESS,
                payload: {
                  id: docSnapshot.id,
                  ...template,
                },
              });
            }
          }
        );

        unsubscribeCallbacks.current.push(unsubscribe);
      }
    } catch (error) {
      dispatch({
        type: actionTypes.FETCH_STORE_TEMPLATE_FAILURE,
        payload: error,
      });
    }
  };

  const search = useCallback(
    ({ storeName }) => {
      if (user) {
        try {
          dispatch({ type: actionTypes.SEARCH_STORE });

          const unsubscribe = onSnapshot(
            doc(db, SHOPS, storeName.trim()),
            (docSnapshot) => {
              const data = {
                id: docSnapshot.id,
                ...docSnapshot.data(),
              };

              if (data.name) {
                dispatch({
                  type: actionTypes.SEARCH_STORE_SUCCESS,
                  payload: data,
                });

                fetchStoreTemplates(data);
              } else {
                dispatch({
                  type: actionTypes.SEARCHED_STORE_NOT_FOUND,
                });
              }
            },
            () => {
              dispatch({
                type: actionTypes.SEARCHED_STORE_NOT_FOUND,
              });
            }
          );

          unsubscribeCallbacks.current.push(unsubscribe);
        } catch (error) {
          dispatch({
            type: actionTypes.SEARCH_STORE_FAILURE,
            payload: error,
          });
        }
      }
    },
    [user]
  );

  const update = useCallback(
    async ({ settings, ...values }) => {
      try {
        dispatch({ type: actionTypes.UPDATE_STORE });

        const newStore = {
          ...values,
          selectors: {
            ...values.selectors,
            message: values.selectors.message
              ? values.selectors.message
              : deleteField(),
            badge: values.selectors.badge
              ? values.selectors.badge
              : deleteField(),
            collection: values.selectors.collection
              ? processNestedFields(values.selectors.collection)
              : deleteField(),
            hide: {
              product: values.selectors.hide
                ? values.selectors.hide.product
                  ? values.selectors.hide.product
                  : deleteField()
                : deleteField(),
              collection: values.selectors.hide
                ? values.selectors.hide.collection
                  ? values.selectors.hide.collection
                  : deleteField()
                : deleteField(),
            },
            variantChangingTime: values.selectors.variantChangingTime
              ? values.selectors.variantChangingTime
              : deleteField(),
          },
        };

        if (
          newStore.selectors?.hide?.product._methodName === "deleteField" &&
          newStore.selectors?.hide?.collection._methodName === "deleteField"
        ) {
          newStore.selectors.hide = deleteField();
        }

        const docRef = doc(db, SHOPS, state.store.id);

        await setDoc(docRef, newStore, { merge: true });
        await updateStoreTemplates(
          state.store.settings.templates,
          settings.templates
        );

        dispatch({ type: actionTypes.UPDATE_STORE_SUCCESS });

        return Promise.resolve();
      } catch (error) {
        dispatch({
          type: actionTypes.UPDATE_STORE_FAILURE,
          payload: error,
        });

        return Promise.reject();
      }
    },
    [state.store]
  );

  const updateStoreTemplates = async (
    prevTemplateValues,
    newTemplateValues
  ) => {
    for (const [type, value] of Object.entries(prevTemplateValues)) {
      const isHavingButtonCollection = type === SETTING_TYPES.PO || type === SETTING_TYPES.IS;

      const wereValuesUpdated =
        value.badge.collection !== newTemplateValues[type].badge.collection ||
        value.badge.product !== newTemplateValues[type].badge.product ||
        (isHavingButtonCollection &&
          value.button.collection?.isEnabled !==
            newTemplateValues[type].button.collection?.isEnabled);

      if (wereValuesUpdated) {
        await updateDoc(doc(db, SHOPS, state.store.id, SETTINGS, value.id), {
          badge: newTemplateValues[type].badge,
          ...(isHavingButtonCollection && {
            "button.collection.isEnabled":
              newTemplateValues[type].button.collection.isEnabled,
          }),
        });
      }
    }
  };

  return (
    <StoresContext.Provider
      value={{
        ...state,
        searchStore: search,
        updateStore: update,
      }}
    >
      {children}
    </StoresContext.Provider>
  );
};

export { StoresContext, StoresProvider };
