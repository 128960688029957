const authActionTypes = {
  // login
  LOGIN: "LOGIN",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  // logout
  LOGOUT: "LOGOUT",
};

export default authActionTypes;
