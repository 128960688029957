import { lazy, Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
// components
import Layout from "./components/layout/Layout";
import { Loader } from "./components";
import RequireAuth from "./components/layout/RequireAuth";
// constants
import { APP_ROUTES } from "./common/constants";

// app pages
const Login = lazy(() => import("./pages/Login"));
const Stores = lazy(() => import("./pages/Stores"));

const AppRoutes = () => {
  return (
    <Routes>
      <Route path={APP_ROUTES.ROOT} element={<RequireAuth />}>
        <Route
          path={APP_ROUTES.ROOT}
          element={<Navigate to={APP_ROUTES.STORES} replace />}
        />
        
        {/* protected routes */}
        <Route element={<Layout />}>
          <Route
            index
            path={APP_ROUTES.STORES}
            element={
              <Suspense fallback={<Loader />}>
                <Stores />
              </Suspense>
            }
          />
        </Route>
      </Route>

      {/* public routes */}
      <Route
        path={APP_ROUTES.LOGIN}
        element={
          <Suspense fallback={<Loader />}>
            <Login />
          </Suspense>
        }
      />

      <Route
        path="*"
        element={<Navigate to={APP_ROUTES.ROOT} replace />}
      />
    </Routes>
  );
};

export default AppRoutes;
